import styles from './Chip.module.css';

interface ChipProps {
  label: string;
}

const Chip = ({ label }: ChipProps) => {
  const variant =
    label === 'Реклама'
      ? 'reklama'
      : label === 'Акція'
        ? 'akcija'
        : 'pryvitannia';

  return <div className={`${styles.chip} ${styles[variant]}`}>{label}</div>;
};

export default Chip;
