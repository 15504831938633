import React, { useState } from 'react';
import {
  Control,
  Controller,
  useFormContext,
  useFormState,
} from 'react-hook-form';
import style from './PasswordFields.module.css';
import Visibility from './icon/visibility';
import VisibilityOff from './icon/visibilityOff';

interface PasswordFieldsProps {
  control: Control<any>;
  isPasswordVisible: boolean;
  isNewPasswordVisible: boolean;
  isConfirmPasswordVisible: boolean;
  togglePasswordVisibility: () => void;
  toggleNewPasswordVisibility: () => void;
  toggleConfirmPasswordVisibility: () => void;
}

const PasswordFields: React.FC<PasswordFieldsProps> = ({
  control,
  isPasswordVisible,
  isNewPasswordVisible,
  isConfirmPasswordVisible,
  togglePasswordVisibility,
  toggleNewPasswordVisibility,
  toggleConfirmPasswordVisibility,
}) => {
  const [activeField, setActiveField] = useState<string | null>(null);
  const { errors } = useFormState({ control });
  const { getValues } = useFormContext();

  const handleFocus = (fieldName: string) => {
    setActiveField(fieldName);
  };

  const handleBlur = () => {
    setActiveField(null);
  };

  return (
    <div className={style.inputPassWrap}>
      <div className={style.titelPassWrap}>
        <h6>Створення нового паролю</h6>
        <p>
          Будь ласка, створіть новий пароль для входу до вашого облікового
          запису.
        </p>
      </div>

      <Controller
        name="password"
        control={control}
        render={({ field }) => (
          <div
            className={`${style.passwordField} ${
              activeField === 'password' ? style.active : ''
            }`}
          >
            <label>Поточний пароль</label>
            <input
              type={isPasswordVisible ? 'text' : 'password'}
              {...field}
              onFocus={() => handleFocus('password')}
              onBlur={handleBlur}
            />
            <button
              type="button"
              className={style.togglePassword}
              onClick={togglePasswordVisibility}
            >
              {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
            </button>
          </div>
        )}
      />

      <Controller
        name="newPassword"
        control={control}
        rules={{
          required: 'Новий пароль обов’язковий.',
          minLength: {
            value: 8,
            message: 'Пароль повинен містити щонайменше 8 символів.',
          },
          pattern: {
            value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/,
            message:
              'Пароль повинен включати щонайменше одну літеру та одну цифру або символ.',
          },
        }}
        render={({ field }) => (
          <div
            className={`${style.passwordField} ${
              activeField === 'newPassword' ? style.active : ''
            }`}
          >
            <label>Новий пароль</label>
            <input
              type={isNewPasswordVisible ? 'text' : 'password'}
              {...field}
              onFocus={() => handleFocus('newPassword')}
              onBlur={handleBlur}
            />
            <button
              type="button"
              className={style.togglePassword}
              onClick={toggleNewPasswordVisibility}
            >
              {isNewPasswordVisible ? <VisibilityOff /> : <Visibility />}
            </button>
            {errors.newPassword && (
              <p className={style.errorMessage}>
                {errors.newPassword.message?.toString()}
              </p>
            )}
          </div>
        )}
      />

      <Controller
        name="confirmPassword"
        control={control}
        rules={{
          required: 'Повторіть пароль обов’язковий.',
          validate: (value) =>
            value === getValues('newPassword') || 'Паролі не співпадають.', // Використання getValues для отримання значення нового паролю
        }}
        render={({ field }) => (
          <div
            className={`${style.passwordField} ${
              activeField === 'confirmPassword' ? style.active : ''
            }`}
          >
            <label>Повторіть Пароль</label>
            <input
              type={isConfirmPasswordVisible ? 'text' : 'password'}
              {...field}
              onFocus={() => handleFocus('confirmPassword')}
              onBlur={handleBlur}
            />
            <button
              type="button"
              className={style.togglePassword}
              onClick={toggleConfirmPasswordVisibility}
            >
              {isConfirmPasswordVisible ? <VisibilityOff /> : <Visibility />}
            </button>
            {errors.confirmPassword && (
              <p className={style.errorMessage}>
                {errors.confirmPassword.message?.toString()}
              </p>
            )}
          </div>
        )}
      />

      <p>
        Пароль повинен містити щонайменше 8 символів.
        <br /> Включати одну літеру та одну цифру або символ.
      </p>
    </div>
  );
};

export default PasswordFields;
