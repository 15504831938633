import { createReducer } from '@reduxjs/toolkit';

import {
  loadCancelTranslationFailure,
  loadCancelTranslationRequest,
  loadCancelTranslationSuccess,
} from '../actions/cancelTranslationActions';

interface HistoryState {
  cancelTranslationData: any[];
  loading: boolean;
  error: string | null;
  pageIndex: number;
  orderDirection: string;
  hasNextPage: boolean;
}

export const initialState: HistoryState = {
  cancelTranslationData: [],
  loading: false,
  error: null,
  pageIndex: 0,
  orderDirection: 'ASC',
  hasNextPage: true,
};

const cancelTranslationReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(loadCancelTranslationRequest, (state) => {
      state.loading = true;
    })
    .addCase(loadCancelTranslationSuccess, (state, action) => {
      state.loading = false;
      state.cancelTranslationData = action.payload.items;
      state.pageIndex = action.payload.pageIndex;
      state.hasNextPage = action.payload.hasNextPage;
    })
    .addCase(loadCancelTranslationFailure, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
});

export default cancelTranslationReducer;
