import React from 'react';
import styles from './DatePicker.module.css';

interface DatePickerProps {
  month: number;
  year: number;
  selectedDate: Date | null;
  onSelect: (date: Date | null) => void;
}

const DatePicker: React.FC<DatePickerProps> = ({
  month,
  year,
  selectedDate,
  onSelect,
}) => {
  const daysInMonth = new Date(year, month + 1, 0).getDate();
  const firstDayOfMonth = new Date(year, month, 1).getDay();
  const daysInPreviousMonth = new Date(year, month, 0).getDate();

  const weeks: (Date | null)[][] = [];
  let currentDay = 1 - ((firstDayOfMonth + 6) % 7);

  while (currentDay <= daysInMonth) {
    const week: (Date | null)[] = Array(7).fill(null);
    for (let i = 0; i < 7; i++) {
      if (currentDay > 0 && currentDay <= daysInMonth) {
        week[i] = new Date(year, month, currentDay);
      } else if (currentDay <= 0) {
        week[i] = new Date(year, month - 1, daysInPreviousMonth + currentDay);
      } else {
        week[i] = new Date(year, month + 1, currentDay - daysInMonth);
      }
      currentDay++;
    }
    weeks.push(week);
  }

  const isSameDay = (date1: Date, date2: Date): boolean => {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  };

  const handleDateClick = (date: Date | null) => {
    if (!date || date.getMonth() !== month) return;
    onSelect(date);
  };

  return (
    <div className={styles.calendarContainer}>
      <div className={styles.calendarGrid}>
        {weeks.map((week, weekIndex) => (
          <React.Fragment key={weekIndex}>
            {week.map((day, dayIndex) => {
              const isLeadingEmpty = day && day.getMonth() < month;
              const isTrailingEmpty = day && day.getMonth() > month;
              const isSelected =
                selectedDate && day && isSameDay(selectedDate, day);

              const cellClassNames = [
                styles.calendarCell,
                isLeadingEmpty ? styles.leadingEmpty : '',
                isTrailingEmpty ? styles.trailingEmpty : '',
                isSelected ? styles.selected : '',
              ].join(' ');

              return (
                <div
                  key={dayIndex}
                  className={cellClassNames}
                  onClick={() => handleDateClick(day)}
                >
                  {day ? day.getDate() : ''}
                </div>
              );
            })}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default DatePicker;
