import React from 'react';
import styles from './TextArea.module.css';

interface TextAreaProps {
  type: string;
  name: string;
  value?: any;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  required?: boolean;
  readOnly?: boolean;
  label: string;
  error?: string;
}

const TextAreaComponent = (props: TextAreaProps) => {
  const textAreaClassName = props.error
    ? `${styles.textAreaError}`
    : `${styles.textarea}`;

  return (
    <div className={styles.container}>
      <label className={styles.label}>{props.label}</label>
      <textarea
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        required={props.required}
        readOnly={props.readOnly}
        className={textAreaClassName}
      />
      {props.error && <span className={styles.error}>{props.error}</span>}
    </div>
  );
};

export default TextAreaComponent;
