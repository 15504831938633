import dayjs from 'dayjs';
import 'dayjs/locale/uk';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(customParseFormat);
dayjs.extend(localeData);
dayjs.extend(relativeTime);
dayjs.locale('uk');
dayjs.extend(utc);
dayjs.extend(timezone);

export const formatTime = (timeString: string) => {
  const formattedTime = dayjs
    .utc(timeString, 'HH:mm:ss')
    .tz('Europe/Kyiv')
    .format('HH:mm');

  return formattedTime;
};

export const formatDate = (date: string) => {
  const now = dayjs();
  const notificationDate = dayjs(date);

  if (
    now.month() === notificationDate.month() &&
    now.year() === notificationDate.year()
  ) {
    return notificationDate.format('D MMMM');
  } else {
    return notificationDate.format('DD.MM.YYYY');
  }
};
