import axiosInstance from '../../utils/axiosInstance';
import {
  loadHistoryFailure,
  loadHistoryRequest,
  loadHistorySuccess,
} from '../actions/historyActions';
import { AppDispatch } from '../store';

export const fetchHistory =
  (
    pageIndex: number,
    pageSize: number,
    searchString: string = '',
    startAt: string = '',
    finishedAt: string = '',
    orderDirection: string = 'ASC',
    orderField: string = 'createdAt',
  ) =>
  async (dispatch: AppDispatch) => {
    dispatch(loadHistoryRequest({ pageIndex, pageSize, orderDirection }));
    try {
      const response = await axiosInstance.post(
        '/Admin/GetCallTranslationHistoryList',
        {
          pageIndex,
          pageSize,
          searchString,
          startAt: startAt ? startAt : undefined,
          finishedAt: finishedAt ? finishedAt : undefined,
          orderDirection,
          orderField,
        },
      );
      const history = response.data;
      dispatch(loadHistorySuccess(history));
      return history;
    } catch (error: any) {
      dispatch(loadHistoryFailure(error.message));
      return { items: [], hasMore: false };
    }
  };
