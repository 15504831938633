import { useEffect, useState } from 'react';
import CalendarIcon from '../icons/Calendar';
import style from './DateInputs.module.css';

interface DateInputsProps {
  value: {
    startDate: string;
    endDate: string;
  };
  onDateChange: (newDates: { startDate: string; endDate: string }) => void;
}

const DateInputs = ({ value, onDateChange }: DateInputsProps) => {
  const [adjustedEndDate, setAdjustedEndDate] = useState(value.endDate);

  useEffect(() => {
    if (value.endDate) {
      const endDate = new Date(value.endDate);
      endDate.setDate(endDate.getDate() - 1);
      setAdjustedEndDate(endDate.toISOString().split('T')[0]);
    }
  }, [value.endDate]);

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onDateChange({ ...value, startDate: e.target.value });
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onDateChange({ ...value, endDate: e.target.value });
  };
  return (
    <div className={style.inputsContainer}>
      <div className={style.inputContainer}>
        <label>Початок</label>
        <input
          type="text"
          value={value.startDate}
          onChange={handleStartDateChange}
        />
        <div className={style.calendarIcon}>
          <CalendarIcon />
        </div>
      </div>
      <div className={style.inputContainer}>
        <label>Кінець</label>
        <input
          type="text"
          value={adjustedEndDate}
          onChange={handleEndDateChange}
        />
        <div className={style.calendarIcon}>
          <CalendarIcon />
        </div>
      </div>
    </div>
  );
};

export default DateInputs;
