import React from 'react';
import { Control, Controller } from 'react-hook-form';
import ImageUpload from '../ImageUpload/ImageUpload';
import InputField from '../InputField/InputField';
import style from './UserProfileFields.module.css';

interface UserProfileFieldsProps {
  control: Control<any>;
  imageUrl: string | null;
  handleImageChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleEmailFocus: () => void;
  handlePasswordFocus: () => void;
  isPasswordVisible: boolean;
  togglePasswordVisibility: () => void;
  showPasswordFields: boolean;
}

const UserProfileFields: React.FC<UserProfileFieldsProps> = ({
  control,
  imageUrl,
  handleImageChange,
  handleEmailFocus,
  handlePasswordFocus,
  isPasswordVisible,
  togglePasswordVisibility,
  showPasswordFields,
}) => (
  <>
    <ImageUpload image={imageUrl} handleImageChange={handleImageChange} />
    <div className={style.wrapInput}>
      <Controller
        name="userName"
        control={control}
        render={({ field }) => (
          <InputField
            label="ПІБ"
            value={field.value || ''}
            onChange={field.onChange}
            onBlur={field.onBlur}
            name={field.name}
          />
        )}
      />
      <Controller
        name="phone"
        control={control}
        render={({ field }) => (
          <InputField
            label="Телефон"
            value={field.value || ''}
            onChange={field.onChange}
            onBlur={field.onBlur}
            name={field.name}
          />
        )}
      />
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <InputField
            label="Email"
            value={field.value || ''}
            onChange={field.onChange}
            onBlur={field.onBlur}
            name={field.name}
            onFocus={handleEmailFocus}
          />
        )}
      />
      <Controller
        name="password"
        control={control}
        render={({ field }) => (
          <div className={style.passwordField}>
            <label>{showPasswordFields ? 'Текущий пароль' : 'Пароль'}</label>
            <input
              type={isPasswordVisible ? 'text' : 'password'}
              onFocus={handlePasswordFocus}
              placeholder={!showPasswordFields ? '•••••••••••••••' : ''}
              {...field}
            />
          </div>
        )}
      />
    </div>
  </>
);

export default UserProfileFields;
