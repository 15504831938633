import React, { useState } from 'react';
import Switch from 'react-switch';
import { Employee } from '../../../types';
import axiosInstance from '../../../utils/axiosInstance';
import ConfirmDeleteModal from './ConfirmDeleteModal/ConfirmDeleteModal';
import EmployeeList from './EmployeeList/EmployeeList';
import ModalHeader from './ModalHeader/ModalHeader';
import style from './ModalWindowEmployees.module.css';
import SearchBar from './SearchBar/SearchBar';
import AzIcon from './icons/AzIcon';
import ZaIcon from './icons/ZaIcon';
import { useDeleteEmployee, useEmployeeData, useSearch } from './utils';

interface ModalWindowEmployeesProps {
  selectedClientId: number | null;
  selectedClientName: string;
  onClose: () => void;
  onOpen: () => void;
}

const ModalWindowEmployees: React.FC<ModalWindowEmployeesProps> = ({
  selectedClientId,
  selectedClientName,
  onClose,
  onOpen,
}) => {
  const [orderDirection, setOrderDirection] = useState<'ASC' | 'DESC'>('ASC');
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [showCheckboxes, setShowCheckboxes] = useState(false);
  const [activeCheckboxes, setActiveCheckboxes] = useState<
    { employee: Employee; isResponsible: boolean }[]
  >([]);
  const [employeeToDelete, setEmployeeToDelete] = useState<Employee | null>(
    null,
  );

  const {
    employees,
    hasMore,
    pageIndex,
    setPageIndex,
    fetchEmployees,
    setHasMore,
    isFetchingRef,
  } = useEmployeeData(selectedClientId, orderDirection, '');

  const { searchText, handleSearchChange, handleClearSearch } =
    useSearch(fetchEmployees);

  const deleteEmployee = useDeleteEmployee(
    fetchEmployees,
    selectedClientId,
    searchText,
  );

  const toggleOrderDirection = () => {
    setOrderDirection((prev) => (prev === 'ASC' ? 'DESC' : 'ASC'));
    setPageIndex(0);
    fetchEmployees(0, true, searchText);
  };

  const handleDeleteClick = (employee: Employee) => {
    setEmployeeToDelete(employee);
    setShowConfirmModal(true);
  };

  const handleConfirmDelete = () => {
    if (employeeToDelete) {
      deleteEmployee(employeeToDelete.id);
      setShowConfirmModal(false);
      setEmployeeToDelete(null);
    }
  };

  const handleCancelDelete = () => {
    setShowConfirmModal(false);
    setEmployeeToDelete(null);
  };

  const handleCloseModal = () => {
    setPageIndex(0);
    setHasMore(true);
    setOrderDirection('ASC');
    setShowConfirmModal(false);
    setEmployeeToDelete(null);
    onClose();
  };

  const toggleCheckboxes = () => {
    setShowCheckboxes((prev) => !prev);
  };

  const handleCheckboxChange = (id: string, isChecked: boolean) => {
    const employee = employees.find((e) => e.id === id);
    if (employee) {
      setActiveCheckboxes((prev) => {
        const updated = prev.filter((e) => e.employee.id !== id);
        return [...updated, { employee, isResponsible: isChecked }];
      });
    }
  };

  const handleConfirmClick = async () => {
    try {
      const patchRequests = activeCheckboxes.map(
        ({ employee, isResponsible }) =>
          axiosInstance.patch('/Admin/SetResponsiblePerson', {
            id: employee.id,
            companyId: selectedClientId,
            isResponsible,
          }),
      );

      await Promise.all(patchRequests);

      setShowCheckboxes(false);

      await fetchEmployees(0, true, searchText);
    } catch (error) {
      console.error('Error updating responsibility:', error);
    }
  };

  if (selectedClientId === null) return null;

  return (
    <div className={style.modalOverlay} onClick={handleCloseModal}>
      <div className={style.modalContent} onClick={(e) => e.stopPropagation()}>
        <ModalHeader
          selectedClientName={selectedClientName}
          onClose={handleCloseModal}
        />
        <SearchBar
          searchText={searchText}
          onSearchChange={handleSearchChange}
          onClearSearch={handleClearSearch}
        />
        <div className={style.toggleContainer}>
          <label>
            <Switch
              onChange={toggleCheckboxes}
              checked={showCheckboxes}
              onColor="#0a8080"
              offColor="#ccc"
              onHandleColor="#f2f8f8"
              offHandleColor="#fff"
              checkedIcon={false}
              uncheckedIcon={false}
            />
            Відповідальна особа
          </label>
        </div>
        <div className={style.itemsList}>
          <div className={style.headerList}>
            <p>#</p>
            <div>
              <p onClick={toggleOrderDirection} className={style.infoChange}>
                Піб {orderDirection === 'ASC' ? <AzIcon /> : <ZaIcon />}
              </p>
            </div>
            <p>Email</p>
            <p>Телефон</p>
          </div>
          <EmployeeList
            employees={employees}
            hasMore={hasMore}
            isFetching={isFetchingRef.current}
            onLoadMore={() => setPageIndex((prev) => prev + 1)}
            onDeleteClick={handleDeleteClick}
            showCheckboxes={showCheckboxes}
            selectedEmployees={activeCheckboxes.map(
              ({ employee }) => employee.id,
            )}
            onToggleEmployee={(id, isSelected) =>
              handleCheckboxChange(id, isSelected)
            }
          />
        </div>
        <div className={style.wrapSubmit}>
          {showCheckboxes ? (
            <button onClick={handleConfirmClick} className={style.submitButton}>
              Підтвердити
            </button>
          ) : (
            <button onClick={handleCloseModal} className={style.submitButton}>
              Закрити
            </button>
          )}
        </div>
        {showConfirmModal && employeeToDelete && (
          <ConfirmDeleteModal
            userName={selectedClientName}
            avatarUrl={employeeToDelete.avatarUrl}
            onConfirm={handleConfirmDelete}
            onCancel={handleCancelDelete}
          />
        )}
      </div>
    </div>
  );
};

export default ModalWindowEmployees;
