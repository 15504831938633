import axiosInstance from '../../utils/axiosInstance';
import { PushNotification } from '../../types';

export const fetchNotificationsList = async ({
  pageIndex,
  pageSize,
  isFinished,
  notificationType,
  searchString,
  orderDirection,
  orderField,
}: {
  pageIndex: number;
  pageSize: number;
  isFinished: boolean;
  notificationType: number;
  searchString: string;
  orderDirection: 'asc' | 'desc';
  orderField: string | undefined;
}) => {
  try {
    const response = await axiosInstance.post(
      '/Notification/GetNotificationsList',
      {
        pageIndex,
        pageSize,
        isFinished,
        notificationType,
        searchString,
        orderDirection,
        orderField,
      },
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching notifications:', error);
    throw error;
  }
};

export const deleteNotification = async (notificationId: string) => {
  try {
    const response = await axiosInstance.patch(
      `/Notification/ArchiveNotification`,
      { notificationId },
    );
    return response.data;
  } catch (error) {
    console.error('Error deleting notification:', error);
    throw error;
  }
};

export const createNotification = async (
  notificationData: PushNotification,
) => {
  try {
    const response = await axiosInstance.post(
      '/Notification/CreateNotification',
      notificationData,
    );
    return response.data;
  } catch (error) {
    console.error('Error creating notification:', error);
    throw error;
  }
};

export const updateNotification = async (
  notificationData: PushNotification,
) => {
  try {
    const response = await axiosInstance.put(
      `/Notification/UpdateNotification`,
      notificationData,
    );
    return response.data;
  } catch (error) {
    console.error('Error updating notification:', error);
    throw error;
  }
};
