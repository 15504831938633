import React, { useRef, useEffect } from 'react';
import { Employee } from '../../../../types';
import EmployeeItem from '../EmployeeItem/EmployeeItem';
import style from './EmployeeList.module.css';

interface EmployeeListProps {
  employees: Employee[];
  hasMore: boolean;
  isFetching: boolean;
  onLoadMore: () => void;
  onDeleteClick: (employee: Employee) => void;
  showCheckboxes: boolean;
  selectedEmployees: string[];
  onToggleEmployee: (id: string, isSelected: boolean) => void;
}

const EmployeeList: React.FC<EmployeeListProps> = ({
  employees,
  hasMore,
  isFetching,
  onLoadMore,
  onDeleteClick,
  showCheckboxes,
  selectedEmployees,
  onToggleEmployee,
}) => {
  const employeeContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      const element = employeeContainerRef.current;
      if (
        element &&
        element.scrollTop + element.clientHeight >=
          element.scrollHeight - 500 &&
        hasMore &&
        !isFetching
      ) {
        onLoadMore();
      }
    };

    const element = employeeContainerRef.current;
    element?.addEventListener('scroll', handleScroll);
    return () => element?.removeEventListener('scroll', handleScroll);
  }, [hasMore, isFetching, onLoadMore]);

  return (
    <div className={style.employeeContainer} ref={employeeContainerRef}>
      {employees.length === 0 ? (
        <p className={style.noEmployees}>Співробітників не знайдено</p>
      ) : (
        employees.map((employee, index) => (
          <EmployeeItem
            key={employee.id}
            employee={employee}
            index={index}
            onDeleteClick={onDeleteClick}
            showCheckboxes={showCheckboxes}
            selectedEmployees={selectedEmployees}
            onToggleEmployee={onToggleEmployee}
          />
        ))
      )}
    </div>
  );
};

export default EmployeeList;
