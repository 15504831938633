import { useEffect, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { TranslationHistoryItem } from '../../helpers';
import { HistoryListItem } from '../HistoryListItem/HistoryListItem';
import SortArrow from '../icons/SortArrow';
import SortingAZ from '../icons/SortingAZ';
import SortingZA from '../icons/SortingZA';
import style from './HistoryList.module.css';

interface HistoryListProps {
  handleChangeSort: (field: string) => void;
  fetchMoreData: () => void;
  data: TranslationHistoryItem[];
  hasNextPage: boolean;
  sortDirection: 'asc' | 'desc';
  sortField: string;
  loading: boolean;
}

export const HistoryList = ({
  handleChangeSort,
  fetchMoreData,
  data,
  hasNextPage,
  sortDirection,
  sortField,
  loading,
}: HistoryListProps) => {
  const listRef = useRef<HTMLDivElement | null>(null);
  const initialLoadRef = useRef(false);

  useEffect(() => {
    if (!initialLoadRef.current) {
      fetchMoreData();
      initialLoadRef.current = true;
    }
  }, [fetchMoreData]);

  const handleScroll = () => {
    if (!listRef.current || loading) return;

    const { scrollTop, scrollHeight, clientHeight } = listRef.current;

    if (scrollTop + clientHeight >= scrollHeight - 100 && hasNextPage) {
      fetchMoreData();
    }
  };

  useEffect(() => {
    const currentRef = listRef.current;
    if (currentRef) {
      currentRef.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [data, hasNextPage, loading]);

  const getContainerClassName = () => {
    if (data.length === 0) {
      return `${style.history_content} ${style.history_content_many}`;
    }
    return !hasNextPage
      ? `${style.history_content} ${style.history_content_many}`
      : style.history_content;
  };

  return (
    <div className={getContainerClassName()}>
      <div className={style.head_list}>
        <h5>#</h5>
        <div className={style.initials}>
          <h5>Перекладач</h5>
          <button onClick={() => handleChangeSort('translator')}>
            {sortDirection === 'desc' && sortField === 'translator' ? (
              <SortingZA />
            ) : (
              <SortingAZ />
            )}
          </button>
        </div>
        <div className={style.initials}>
          <h5>Клієнт</h5>
          <button onClick={() => handleChangeSort('client')}>
            {sortDirection === 'desc' && sortField === 'client' ? (
              <SortingZA />
            ) : (
              <SortingAZ />
            )}
          </button>
        </div>
        <h5>Час</h5>
        <div className={style.initials}>
          <h5>Дата</h5>
          <button onClick={() => handleChangeSort('date')}>
            <SortArrow
              className={
                sortDirection === 'desc' && sortField === 'date'
                  ? style.rotated
                  : style.notRotated
              }
            />
          </button>
        </div>
        <div className={style.initials}>
          <h5>Тривалість</h5>
        </div>
        <h5>Ким завершено</h5>
      </div>
      <div className={style.historyList} ref={listRef}>
        {!loading && data.length === 0 ? (
          <div className={style.no_results}>
            <h6 className={style.content}>Історій перекладів не знайдено.</h6>
          </div>
        ) : (
          <InfiniteScroll
            dataLength={data.length}
            next={fetchMoreData}
            hasMore={hasNextPage}
            loader={null}
          >
            <>
              {data.map((item, index) => {
                if (!item || !item.client || !item.client.id) {
                  return null;
                }
                return (
                  <HistoryListItem
                    index={index}
                    key={`${item.client?.id}-${index}`}
                    item={item}
                    dataLength={data.length}
                  />
                );
              })}
              {loading && <div>Завантаження...</div>}
            </>
          </InfiniteScroll>
        )}
      </div>
    </div>
  );
};
