import InfiniteScroll from 'react-infinite-scroll-component';
import { CancelTranslationItem } from '../../helpers';
import { CancelTranslationListItem } from '../CancelTranslationListItem/CancelTranslationListItem';
import SortArrow from '../icons/SortArrow';
import SortingAZ from '../icons/SortingAZ';
import SortingZA from '../icons/SortingZA';
import style from './CancelTranslationList.module.css';

interface CancelTranslationListProps {
  handleChangeSort: (field: string) => void;
  fetchMoreData: () => void;
  data: CancelTranslationItem[];
  hasNextPage: boolean;
  sortDirection: 'asc' | 'desc';
  sortField: string;
  loading: boolean;
  searchQuery: string;
}

export const CancelTranslationList = ({
  handleChangeSort,
  fetchMoreData,
  data,
  hasNextPage,
  sortDirection,
  sortField,
  loading,
  searchQuery,
}: CancelTranslationListProps) => {
  return (
    <div className={style.cancelTranslation_content}>
      <div className={style.head_list}>
        <h5>#</h5>
        <div className={style.initials}>
          <h5>ПІБ</h5>
          <button onClick={() => handleChangeSort('translator')}>
            {sortDirection === 'desc' && sortField === 'translator' ? (
              <SortingZA />
            ) : (
              <SortingAZ />
            )}
          </button>
        </div>
        <div className={style.initials}>
          <h5>ЧАС</h5>
        </div>
        <div className={style.initials}>
          <h5>ДАТА</h5>
          <button onClick={() => handleChangeSort('date')}>
            <SortArrow
              className={
                sortDirection === 'desc' && sortField === 'date'
                  ? style.rotated
                  : style.notRotated
              }
            />
          </button>
        </div>
        <div className={style.initials}>
          <h5>ПРИЧИНА ВІДМОВИ</h5>
        </div>
      </div>
      <div id="scrollableDiv" className={style.cancelTranslationList}>
        <InfiniteScroll
          dataLength={data.length}
          next={fetchMoreData}
          hasMore={hasNextPage}
          loader={''}
          scrollableTarget="scrollableDiv"
          scrollThreshold={0.9}
        >
          {!loading && data.length === 0 ? (
            <div className={style.no_results}>
              <h6 className={style.content}>
                {searchQuery
                  ? 'Немає скасованих перекладів. Всі поточні запити обробляються без змін.'
                  : 'Скасованих перекладів за даним запитом не знайдено.'}
              </h6>
            </div>
          ) : (
            data.map((item, index) => (
              <CancelTranslationListItem
                index={index}
                key={`${item.translator.id}-${index}`}
                item={item}
                dataLength={data.length}
              />
            ))
          )}
        </InfiniteScroll>
      </div>
    </div>
  );
};
