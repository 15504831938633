import React from 'react';

const ResponsibleIcon: React.FC = () => {
  return (
    <svg
      width="18"
      height="13"
      viewBox="0 0 18 13"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.54961 13L0.849609 7.29998L2.27461 5.87498L6.54961 10.15L15.7246 0.974976L17.1496 2.39998L6.54961 13Z" />
    </svg>
  );
};

export default ResponsibleIcon;
