import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { fetchHistory } from '../../store/thunks/historyThunk';
import style from './History.module.css';
import ControlPanel from './components/ControlPanel/ControlPanel';
import { HistoryList } from './components/HistoryList/HistoryList';
import { TranslationHistoryItem } from './helpers';

const History = () => {
  const dispatch: AppDispatch = useDispatch();
  const { history, loading, hasNextPage } = useSelector(
    (state: RootState) => state.history,
  );

  const [data, setData] = useState<TranslationHistoryItem[]>([]);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [dateValues, setDateValues] = useState<{
    startDate: string;
    endDate: string;
  }>({ startDate: '', endDate: '' });
  const [sortField, setSortField] = useState<string>('date');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [debounceTimeout, setDebounceTimeout] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);

  const isFirstLoad = useRef(true);

  const fetchData = () => {
    const adjustedEndDate = dateValues.endDate
      ? dayjs(dateValues.endDate).add(1, 'day').toISOString()
      : undefined;

    dispatch(
      fetchHistory(
        pageIndex,
        15,
        searchQuery,
        dateValues.startDate
          ? new Date(dateValues.startDate).toISOString()
          : undefined,
        adjustedEndDate,
        sortDirection,
        sortField,
      ),
    );
  };

  useEffect(() => {
    if (isFirstLoad.current) {
      isFirstLoad.current = false;
      return;
    }
    setPageIndex(1);
    setData([]);
  }, [searchQuery, dateValues, sortField, sortDirection]);

  useEffect(() => {
    if (pageIndex > 1) {
      fetchData();
    }
  }, [pageIndex]);

  useEffect(() => {
    setData((prev) => {
      if (pageIndex === 1) {
        return history.filter((item) => item?.client?.id);
      } else {
        const newData = history.filter(
          (newItem) =>
            newItem?.client?.id &&
            !prev.some(
              (prevItem) =>
                prevItem?.client?.id === newItem.client.id &&
                prevItem?.createdAt === newItem?.createdAt,
            ),
        );
        return [...prev, ...newData];
      }
    });
  }, [history, pageIndex]);

  useEffect(() => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const timeout = setTimeout(() => {
      setPageIndex(1);
      setData([]);
      fetchData();
    }, 1000);

    setDebounceTimeout(timeout);

    return () => clearTimeout(timeout);
  }, [searchQuery, dateValues, sortField, sortDirection]);

  const fetchMoreData = () => {
    if (!loading && hasNextPage) {
      setPageIndex((prev) => prev + 1);
    }
  };

  const handleChangeSort = (field: string) => {
    setPageIndex(1);
    setData([]);
    if (field === sortField) {
      setSortDirection((prevDirection) =>
        prevDirection === 'asc' ? 'desc' : 'asc',
      );
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const handleChangeSearch = (query: string) => {
    setSearchQuery(query);
  };

  const handleChangeDate = (dates: { startDate: string; endDate: string }) => {
    setDateValues(dates);
  };

  return (
    <div className={style.history_container}>
      <ControlPanel
        searchQuery={searchQuery}
        setSearchQuery={handleChangeSearch}
        dateValues={dateValues}
        setDateValues={handleChangeDate}
      />
      <HistoryList
        handleChangeSort={handleChangeSort}
        fetchMoreData={fetchMoreData}
        data={data}
        hasNextPage={hasNextPage}
        sortDirection={sortDirection}
        sortField={sortField}
        loading={loading}
      />
    </div>
  );
};

export default History;
