import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { showSnackbar } from '../../store/reducers/snackbarSlice';
import { EmailUpdateData, PasswordData, UserData } from '../../types';
import {
  fetchUserData,
  resendOtpForEmailUpdate,
  sendOtpForEmailUpdate,
  updateEmailWithOtp,
  updatePassword,
  updateUserData,
  uploadAvatar,
} from './apiHelper';
import EmailFields from './EmailFields/EmailFields';
import BackArrowIcon from './icon/BackArrowIcon';
import PasswordFields from './PasswordFields/PasswordFields';
import style from './Preferences.module.css';
import PreferencesHeader from './PreferencesHeader/PreferencesHeader';
import UserProfileFields from './UserProfileFields/UserProfileFields';

interface PreferencesProps {
  onClose: () => void;
}

const Preferences: React.FC<PreferencesProps> = ({ onClose }) => {
  const methods = useForm<UserData & PasswordData & EmailUpdateData>({
    mode: 'onChange',
  });
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { isDirty },
  } = methods;

  const [isLoading, setIsLoading] = useState(true);
  const [uploadedImage, setUploadedImage] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [initialData, setInitialData] = useState<UserData | null>(null);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);
  const [showPasswordFields, setShowPasswordFields] = useState(false);
  const [showEmailUpdateField, setShowEmailUpdateField] = useState(false);
  const [showOtpField, setShowOtpField] = useState(false);
  const hasFetchedRef = useRef(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!hasFetchedRef.current) {
      hasFetchedRef.current = true;

      fetchUserData()
        .then((data) => {
          setValue('phone', data.phone);
          setValue('userName', data.userName);
          setValue('avatarUrl', data.avatarUrl);
          setValue('email', data.email);
          setImageUrl(data.avatarUrl);
          setInitialData(data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching user data:', error);
          dispatch(
            showSnackbar({
              message: 'Сталася помилка при завантаженні інформації',
              type: 'error',
            }),
          );
          setIsLoading(false);
        });
    }
  }, [setValue, dispatch]);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) return;

    const file = e.target.files[0];
    setUploadedImage(file);

    const localImageUrl = URL.createObjectURL(file);
    setImageUrl(localImageUrl);

    return () => {
      URL.revokeObjectURL(localImageUrl);
    };
  };

  const onSubmit = async (data: UserData & PasswordData & EmailUpdateData) => {
    if (!isDirty && !uploadedImage) return;

    const updatedData: Partial<UserData> = {};

    const isUserNameChanged = data.userName !== initialData?.userName;
    const isPhoneChanged = data.phone !== initialData?.phone;

    if (isUserNameChanged || isPhoneChanged) {
      updatedData.userName = data.userName;
      updatedData.phone = data.phone;
    }

    try {
      if (Object.keys(updatedData).length > 0) {
        await updateUserData(updatedData);
      }

      if (uploadedImage) {
        const response = await uploadAvatar(uploadedImage);

        if (response && response.imageUrl) {
          const newAvatarUrl = response.imageUrl;
          setImageUrl(newAvatarUrl);
          setUploadedImage(null);
        } else {
          console.error('Invalid response structure:', response);
          throw new Error('Invalid response from uploadAvatar');
        }
      }

      if (showPasswordFields) {
        await updatePassword({
          oldPassword: data.password,
          newPassword: data.newPassword,
          confirmPassword: data.confirmPassword,
        });
        setShowPasswordFields(false);
      }

      if (showEmailUpdateField && !showOtpField) {
        await sendOtpForEmailUpdate(data.newEmail);
        setShowOtpField(true);
      }

      if (showOtpField) {
        await updateEmailWithOtp(data.newEmail, data.otp || '');
        const updatedUserData = await fetchUserData();
        setValue('email', updatedUserData.email);
        setInitialData(updatedUserData);

        handleReturnToDefaultState();
      }

      dispatch(
        showSnackbar({
          message: 'Дані успішно оновлені',
          type: 'success',
        }),
      );
    } catch (error) {
      console.error('Error updating data:', error);
      dispatch(
        showSnackbar({
          message: 'Сталася помилка при оновленні даних',
          type: 'error',
        }),
      );
    }
  };

  const handleResendOtp = async (email: string) => {
    try {
      await resendOtpForEmailUpdate({ email });
      dispatch(
        showSnackbar({
          message: 'OTP успішно відправлено',
          type: 'success',
        }),
      );
    } catch (error) {
      console.error('Error resending OTP:', error);
      dispatch(
        showSnackbar({
          message: 'Сталася помилка при повторній відправці OTP',
          type: 'error',
        }),
      );
    }
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prev) => !prev);
  };

  const toggleNewPasswordVisibility = () => {
    setIsNewPasswordVisible((prev) => !prev);
  };

  const toggleConfirmPasswordVisibility = () => {
    setIsConfirmPasswordVisible((prev) => !prev);
  };

  const handlePasswordFocus = () => {
    setShowPasswordFields(true);
    setShowEmailUpdateField(false);
    setShowOtpField(false);
  };

  const handleEmailFocus = () => {
    setShowEmailUpdateField(true);
    setShowPasswordFields(false);
    setShowOtpField(false);
  };

  const handleReturnToDefaultState = () => {
    setShowPasswordFields(false);
    setShowEmailUpdateField(false);
    setShowOtpField(false);
    setValue('password', '');
    setValue('newPassword', '');
    setValue('confirmPassword', '');
    setValue('newEmail', '');
    setValue('otp', '');
  };

  return (
    <div className={style.modalOverlay} onClick={onClose}>
      <div className={style.modalContent} onClick={(e) => e.stopPropagation()}>
        <PreferencesHeader onClose={onClose} />
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={style.preferenceForm}
          >
            {showPasswordFields || showEmailUpdateField ? (
              <div className={style.wrapChangesForm}>
                <div className={style.backButton}>
                  <button
                    type="button"
                    className={style.returnButton}
                    onClick={handleReturnToDefaultState}
                  >
                    <BackArrowIcon />
                  </button>
                </div>
                {showPasswordFields && (
                  <PasswordFields
                    control={control}
                    isPasswordVisible={isPasswordVisible}
                    isNewPasswordVisible={isNewPasswordVisible}
                    isConfirmPasswordVisible={isConfirmPasswordVisible}
                    togglePasswordVisibility={togglePasswordVisibility}
                    toggleNewPasswordVisibility={toggleNewPasswordVisibility}
                    toggleConfirmPasswordVisibility={
                      toggleConfirmPasswordVisibility
                    }
                  />
                )}
                {showEmailUpdateField && (
                  <EmailFields
                    control={control}
                    showOtpField={showOtpField}
                    handleResendOtp={handleResendOtp}
                    getValues={getValues}
                  />
                )}
              </div>
            ) : (
              <UserProfileFields
                control={control}
                imageUrl={imageUrl}
                handleImageChange={handleImageChange}
                handleEmailFocus={handleEmailFocus}
                handlePasswordFocus={handlePasswordFocus}
                isPasswordVisible={isPasswordVisible}
                togglePasswordVisibility={togglePasswordVisibility}
                showPasswordFields={showPasswordFields}
              />
            )}
            <div className={style.buttonContainer}>
              <button type="submit" className={style.saveButton}>
                {showOtpField ? 'Підтвердити' : 'Зберегти'}
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default Preferences;
