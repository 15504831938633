import { debounce } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import PushPanel from '../PushPanel/PushPanel';
import style from './push.module.css';
import { fetchNotificationsList, deleteNotification } from './pushService';
import SortingAZ from './icon/SortingAZ';
import SortingZA from './icon/SortingZA';
import SortArrow from './icon/SortArrow';
import NotificationForm from './NotificationForm/NotificationForm';
import Chip from './Chip/Chip';
import MoreVert from './icon/MoreVert';
import NotificationMenu from './NotificationMenu/NotificationMenu';
import ConfirmModal from './ConfirmationModal/ConfirmationModal';
import { formatTime, formatDate } from './helpers';
import { PushNotification } from '../../types';

const PushComponent: React.FC = () => {
  const [notifications, setNotifications] = useState<any[]>([]);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [activeType, setActiveType] = useState<'active' | 'completed'>(
    'active',
  );
  const [showNotificationModal, setShowNotificationModal] =
    useState<boolean>(false);
  const [activeFilter, setActiveFilter] = useState<number | undefined>(
    undefined,
  );
  const [sortField, setSortField] = useState<string>('');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [buttonRef, setButtonRef] = useState<{
    current: HTMLButtonElement | null;
  } | null>(null);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [selectedNotificationId, setSelectedNotificationId] =
    useState<string>('');
  const [editNotificationData, setEditNotificationData] =
    useState<PushNotification | null>(null);

  const isRequestInProgress = useRef(false);
  const notificationRefs = useRef<(HTMLButtonElement | null)[]>([]);

  const fetchNotifications = useCallback(async () => {
    if (isRequestInProgress.current) return;
    isRequestInProgress.current = true;

    setIsFetching(true);
    try {
      const data = await fetchNotificationsList({
        pageIndex,
        pageSize: 10,
        isFinished: activeType === 'completed',
        notificationType: Number(activeFilter),
        searchString: searchQuery,
        orderDirection: sortDirection,
        orderField: sortField ? sortField : undefined,
      });

      if (pageIndex === 1) {
        setNotifications(data.items);
      } else {
        setNotifications((prev) => [...prev, ...data.items]);
      }

      setHasMore(data.items.length > 0 && data.hasNextPage);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    } finally {
      setIsFetching(false);
      isRequestInProgress.current = false;
    }
  }, [
    pageIndex,
    searchQuery,
    activeType,
    activeFilter,
    sortDirection,
    sortField,
  ]);

  useEffect(() => {
    fetchNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchQuery,
    activeType,
    activeFilter,
    sortDirection,
    sortField,
    pageIndex,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((query: string) => {
      setSearchQuery(query);
    }, 1000),
    [],
  );

  const handleSearch = (query: string) => {
    setPageIndex(1);
    debouncedSearch(query);
  };

  const handleClearSearch = () => {
    setSearchQuery('');
    setNotifications([]);
  };

  const handleActiveClick = () => {
    setPageIndex(1);
    setActiveType('active');
  };

  const handleCompletedClick = () => {
    setPageIndex(1);
    setActiveType('completed');
  };

  const handleFilterClick = (filter: number | undefined) => {
    setPageIndex(1);
    setActiveFilter(filter);
  };

  const refreshNotifications = () => {
    pageIndex === 1 ? fetchNotifications() : setPageIndex(1);
    setEditNotificationData(null);
  };

  const fetchMoreData = () => {
    setPageIndex((prev) => prev + 1);
  };

  const handleChangeSort = (field: string) => {
    setPageIndex(1);
    setNotifications([]);
    if (field === sortField) {
      setSortDirection((prevDirection: string) =>
        prevDirection === 'asc' ? 'desc' : 'asc',
      );
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const handleNotificationMenuClick = (
    notificationId: string,
    index: number,
  ) => {
    setSelectedNotificationId(notificationId);
    setButtonRef({ current: notificationRefs.current[index] });
    setIsMenuOpen(true);
  };

  const handleEditNotification = () => {
    const notification = notifications.find(
      (n) => n.id === selectedNotificationId,
    );
    setEditNotificationData(notification);
    setShowNotificationModal(true);
    setIsMenuOpen(false);
  };

  const handleOpenConfirmModal = () => {
    const notification = notifications.find(
      (n) => n.id === selectedNotificationId,
    );
    setEditNotificationData(notification);
    setIsConfirmModalOpen(true);
    setIsMenuOpen(false);
  };

  const handleDeleteNotification = async () => {
    await deleteNotification(selectedNotificationId);
    refreshNotifications();
    setIsConfirmModalOpen(false);
  };

  const handleCancelDelete = () => {
    setIsConfirmModalOpen(false);
  };

  return (
    <div className={style.wrapPush}>
      <PushPanel
        onSearch={handleSearch}
        onClear={handleClearSearch}
        onActiveClick={handleActiveClick}
        onCompletedClick={handleCompletedClick}
        activeType={activeType}
        onCreateClick={() => setShowNotificationModal(true)}
      />
      <div
        className={`${style.notificationsList} ${
          !isFetching &&
          (!notifications.length || !hasMore) &&
          style.borderBottomRadius
        }`}
      >
        <div className={style.typpePush}>
          <div
            className={
              activeFilter === undefined
                ? style.activeFilter
                : style.nonActiveFilter
            }
            onClick={() => handleFilterClick(undefined)}
          >
            Всі
          </div>
          <div
            className={
              activeFilter === 1 ? style.activeFilter : style.nonActiveFilter
            }
            onClick={() => handleFilterClick(1)}
          >
            Акційні
          </div>
          <div
            className={
              activeFilter === 2 ? style.activeFilter : style.nonActiveFilter
            }
            onClick={() => handleFilterClick(2)}
          >
            Рекламні
          </div>
          <div
            className={
              activeFilter === 3 ? style.activeFilter : style.nonActiveFilter
            }
            onClick={() => handleFilterClick(3)}
          >
            Пропозиції
          </div>
        </div>
        <div className={style.controlHead}>
          <h6>#</h6>
          <div className={style.sortBtnWrap}>
            <h6>Назва</h6>
            <button
              onClick={() => handleChangeSort('name')}
              className={style.sortButton}
            >
              {sortDirection === 'asc' ? <SortingAZ /> : <SortingZA />}
            </button>
          </div>
          <h6>Тип</h6>
          <div className={style.sortBtnWrap}>
            <h6>Час</h6>
            <button
              onClick={() => handleChangeSort('sendTime')}
              className={style.sortArrowButton}
            >
              <SortArrow
                className={
                  sortField === 'sendTime' && sortDirection === 'desc'
                    ? style.rotated
                    : ''
                }
              />
            </button>
          </div>
          <div className={style.sortBtnWrap}>
            <h6>Початок</h6>
            <button
              onClick={() => handleChangeSort('startDate')}
              className={style.sortArrowButton}
            >
              <SortArrow
                className={
                  sortField === 'startDate' && sortDirection === 'desc'
                    ? style.rotated
                    : ''
                }
              />
            </button>
          </div>
          <div className={style.sortBtnWrap}>
            <h6>Кінець</h6>
            <button
              onClick={() => handleChangeSort('endDate')}
              className={style.sortArrowButton}
            >
              <SortArrow
                className={
                  sortField === 'endDate' && sortDirection === 'desc'
                    ? style.rotated
                    : ''
                }
              />
            </button>
          </div>
          <h6>Повідомлення</h6>
        </div>
        <InfiniteScroll
          dataLength={notifications.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={null}
        >
          {notifications.map((notification, index) => (
            <div key={notification.id} className={style.notificationItem}>
              <div>{index + 1}</div>
              <p className={style.nameCol}>{notification.name}</p>
              <Chip label={notification.type} />
              <p>
                {notification.sendTime
                  ? formatTime(notification.sendTime)
                  : '-'}
              </p>
              <p>
                {notification.startDate
                  ? formatDate(notification.startDate)
                  : '-'}
              </p>
              <p>
                {notification.endDate ? formatDate(notification.endDate) : '-'}
              </p>
              <p className={style.messageCol}>{notification.message}</p>
              <button
                ref={(el) => (notificationRefs.current[index] = el)}
                className={style.notificationMenu}
                onClick={() =>
                  handleNotificationMenuClick(notification.id, index)
                }
              >
                <MoreVert />
              </button>
            </div>
          ))}
        </InfiniteScroll>
        {!isFetching && !notifications.length && (
          <p className={style.emphtyItems}>
            Push-повідомлень немає. Додайте нове, щоб почати.
          </p>
        )}
      </div>
      {showNotificationModal && (
        <NotificationForm
          onClose={() => {
            setShowNotificationModal(false);
            setEditNotificationData(null);
          }}
          onSave={refreshNotifications}
          notificationData={editNotificationData}
        />
      )}
      {isMenuOpen && buttonRef && (
        <NotificationMenu
          onClose={() => setIsMenuOpen(false)}
          buttonRef={buttonRef}
          onEdit={handleEditNotification}
          onDelete={handleOpenConfirmModal}
          selectedNotificationId={selectedNotificationId}
        />
      )}
      {isConfirmModalOpen && selectedNotificationId && (
        <ConfirmModal
          onConfirm={handleDeleteNotification}
          onCancel={handleCancelDelete}
          notifyName={editNotificationData?.name}
        />
      )}
    </div>
  );
};

export default PushComponent;
