import React from 'react';
import { Control, Controller, FieldError } from 'react-hook-form';
import Input from '../input/input';
import Select from '../Select/Select';

interface FieldControllerProps {
  name: string;
  control: Control<any>;
  rules?: any;
  type: string;
  label: string;
  error?: FieldError | undefined;
  options?: { value: string; label: string }[];
  readOnly?: boolean;
  value?: string;
  onFocus?: () => void;
}

const FieldController: React.FC<FieldControllerProps> = ({
  name,
  control,
  rules,
  type,
  label,
  error,
  options,
  readOnly = false,
  value,
}) => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    render={({ field }) =>
      type === 'select' ? (
        <Select
          {...field}
          label={label}
          options={options || []}
          error={error?.message}
          value={value || String(field.value)}
        />
      ) : (
        <Input
          {...field}
          type={type}
          label={label}
          error={error?.message}
          readOnly={readOnly}
          value={readOnly ? value : field.value}
        />
      )
    }
  />
);

export default FieldController;
