import React from 'react';
import { useLocation } from 'react-router-dom';
import SortArrow from '../../icons/SortArrow';
import SortingAZ from '../../icons/SortingAZ';
import SortingZA from '../../icons/SortingZA';
import style from './CustomerListHead.module.css';

interface CustomerListHeadProps {
  sortingLogo: boolean;
  isRotated: boolean;
  toggleSortingDirection: () => void;
  handleSortArrowClick: () => void;
}

const CustomerListHead: React.FC<CustomerListHeadProps> = ({
  sortingLogo,
  isRotated,
  toggleSortingDirection,
  handleSortArrowClick,
}) => {
  const location = useLocation();

  function getClassName(pathname: string) {
    let additionalClass = '';

    if (pathname === '/customer') {
      additionalClass = style.headCustomer;
    } else if (pathname === '/company') {
      additionalClass = style.headCompany;
    }

    return `${style.head_list} ${additionalClass}`;
  }

  return (
    <div className={getClassName(location.pathname)}>
      <h5>#</h5>
      <div className={style.initials}>
        {location.pathname === '/company' && <h5>НАЗВА Компанії</h5>}
        {location.pathname === '/customer' && <h5>ПІБ</h5>}
        <button onClick={toggleSortingDirection}>
          {sortingLogo ? <SortingZA /> : <SortingAZ />}
        </button>
      </div>
      {location.pathname === '/company' && <h5>ЄДРПОУ</h5>}
      {location.pathname === '/customer' && <h5>EMAIL</h5>}
      <h5>Тариф</h5>
      <div className={style.term}>
        <h5>Термін договору</h5>
        <button onClick={handleSortArrowClick}>
          <SortArrow className={isRotated ? style.rotated : ''} />
        </button>
      </div>
      <h5>Телефон</h5>
    </div>
  );
};

export default CustomerListHead;
