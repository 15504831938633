import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { fetchCancelTranslation } from '../../store/thunks/cancelTranslationThunk';
import style from './CancelTranslation.module.css';
import { CancelTranslationList } from './components/CancelTranslationList/CancelTranslationList';
import ControlPanel from './components/ControlPanel/ControlPanel';
import { CancelTranslationItem } from './helpers';

const CancelTranslation = () => {
  const dispatch: AppDispatch = useDispatch();
  const { cancelTranslationData, loading, hasNextPage } = useSelector(
    (state: RootState) => state.cancelTranslation,
  );

  const [data, setData] = useState<CancelTranslationItem[]>([]);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [dateValues, setDateValues] = useState<{
    startDate: string;
    endDate: string;
  }>({ startDate: '', endDate: '' });
  const [sortField, setSortField] = useState<string>('date');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [debounceTimeout, setDebounceTimeout] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);

  useEffect(() => {
    if (searchQuery && searchQuery.length < 2) return;

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const timeout = setTimeout(
      () => {
        const adjustedEndDate = dateValues.endDate
          ? new Date(dateValues.endDate)
          : undefined;

        if (adjustedEndDate) {
          adjustedEndDate.setHours(adjustedEndDate.getHours() + 3);
        }

        dispatch(
          fetchCancelTranslation(
            pageIndex,
            15,
            searchQuery,
            dateValues.startDate
              ? new Date(dateValues.startDate).toISOString()
              : undefined,
            adjustedEndDate ? adjustedEndDate.toISOString() : undefined,
            sortDirection,
            sortField,
          ),
        );
      },
      searchQuery ? 2000 : 0,
    );

    setDebounceTimeout(timeout);

    return () => clearTimeout(timeout);
  }, [
    searchQuery,
    dispatch,
    sortField,
    sortDirection,
    pageIndex,
    dateValues.startDate,
    dateValues.endDate,
  ]);

  useEffect(() => {
    if (pageIndex === 1) {
      setData(cancelTranslationData);
    } else {
      setData((prev) => {
        const newData = cancelTranslationData.filter(
          (newItem) =>
            !prev.some((prevItem) => prevItem.createdAt === newItem.createdAt),
        );
        return [...prev, ...newData];
      });
    }
  }, [cancelTranslationData, pageIndex]);
  const fetchMoreData = () => {
    if (hasNextPage && !loading) {
      setPageIndex((prev) => prev + 1);
    }
  };
  const handleScroll = (event: any) => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;
    const threshold = scrollHeight - clientHeight - 100;
    if (scrollTop >= threshold && data.length >= 12) {
      fetchMoreData();
    }
  };

  const handleChangeSort = (field: string) => {
    setPageIndex(1);
    setData([]);
    if (field === sortField) {
      setSortDirection((prevDirection: string) =>
        prevDirection === 'asc' ? 'desc' : 'asc',
      );
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const handleChangeSearch = (query: string) => {
    if (query.length < 2) {
      setSearchQuery(query);
    } else {
      setPageIndex(1);
      setData([]);
      setSearchQuery(query);
    }
  };

  const handleChangeDate = (dates: { startDate: string; endDate: string }) => {
    setPageIndex(1);
    setData([]);
    setDateValues(dates);
  };

  return (
    <div className={style.cancelTranslation_container} onScroll={handleScroll}>
      <ControlPanel
        searchQuery={searchQuery}
        setSearchQuery={handleChangeSearch}
        dateValues={dateValues}
        setDateValues={handleChangeDate}
      />
      <CancelTranslationList
        handleChangeSort={handleChangeSort}
        fetchMoreData={fetchMoreData}
        data={data}
        hasNextPage={hasNextPage}
        sortDirection={sortDirection}
        sortField={sortField}
        loading={loading}
        searchQuery={searchQuery}
      />
    </div>
  );
};

export default CancelTranslation;
