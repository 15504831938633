import { setSchedule } from '../../../store/reducers/scheduleSlice';
import { AppDispatch } from '../../../store/store';
import { WorkSchedule } from '../../../types';
import axiosInstance from '../../../utils/axiosInstance';

export const fetchTranslatorData = async (
  translatorId: string,
  dispatch: AppDispatch,
) => {
  try {
    const response = await axiosInstance.get(
      `/Admin/Schedule?translatorId=${translatorId}`,
    );
    const data = response.data;
    if (Array.isArray(data) && data.length > 0) {
      const updatedSchedule = data.reduce(
        (acc: Record<string, WorkSchedule[]>, item: WorkSchedule) => {
          const dayOfWeek = new Date(item.startAt).getDay();
          const dayKey = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'][
            dayOfWeek
          ];
          if (dayKey) {
            acc[dayKey].push(item);
          }
          return acc;
        },
        {
          sun: [],
          mon: [],
          tue: [],
          wed: [],
          thu: [],
          fri: [],
          sat: [],
        },
      );

      dispatch(setSchedule(updatedSchedule));
    } else {
      dispatch(
        setSchedule({
          sun: [],
          mon: [],
          tue: [],
          wed: [],
          thu: [],
          fri: [],
          sat: [],
        }),
      );
    }
  } catch (error) {
    console.error('Error fetching schedule data:', error);
  }
};
