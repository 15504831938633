import { setSchedule } from '../../../store/reducers/scheduleSlice';
import { AppDispatch } from '../../../store/store';
import { WorkSchedule } from '../../../types';
import axiosInstance from '../../../utils/axiosInstance';

export const formatDate = (date: Date): string => {
  return date.toISOString().split('T')[0];
};

export const formatTime = (dateString: string): string => {
  const date = new Date(dateString);
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
};

export const getDatesForMonth = (currentMonth: Date): Date[] => {
  const startOfMonth = new Date(
    Date.UTC(currentMonth.getFullYear(), currentMonth.getMonth(), 1),
  );
  const endOfMonth = new Date(
    Date.UTC(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0),
  );
  const dates: Date[] = [];
  let day = new Date(startOfMonth);

  while (day <= endOfMonth) {
    dates.push(new Date(day));
    day.setUTCDate(day.getUTCDate() + 1);
  }

  const daysInWeek = 7;
  const firstDayOfMonthIndex = (startOfMonth.getUTCDay() + 6) % 7;

  const prevMonthEnd = new Date(startOfMonth);
  prevMonthEnd.setUTCDate(0);

  const nextMonthStart = new Date(endOfMonth);
  nextMonthStart.setUTCDate(nextMonthStart.getUTCDate() + 1);

  const prevMonthDays: Date[] = [];
  for (let i = 0; i < firstDayOfMonthIndex; i++) {
    prevMonthDays.unshift(new Date(prevMonthEnd));
    prevMonthEnd.setUTCDate(prevMonthEnd.getUTCDate() - 1);
  }

  const nextMonthDays: Date[] = [];
  for (let i = dates.length + prevMonthDays.length; i % daysInWeek !== 0; i++) {
    nextMonthDays.push(new Date(nextMonthStart));
    nextMonthStart.setUTCDate(nextMonthStart.getUTCDate() + 1);
  }

  return [...prevMonthDays, ...dates, ...nextMonthDays];
};

export const getMonthAndYear = (date: Date): string => {
  return date.toLocaleString('uk-UA', { month: 'long', year: 'numeric' });
};

export const formatToHHMM = (isoString: string): string => {
  const date = new Date(isoString);
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
};

export const fetchUpdatedSchedule = async (
  userId: string,
  dispatch: AppDispatch,
) => {
  try {
    const response = await axiosInstance.get(
      `/Admin/Schedule?translatorId=${userId}`,
    );
    const updatedData = response.data;

    if (Array.isArray(updatedData) && updatedData.length > 0) {
      const updatedSchedule = updatedData.reduce(
        (acc: Record<string, WorkSchedule[]>, item: WorkSchedule) => {
          const dayOfWeek = new Date(item.startAt).getDay();
          const dayKey = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'][
            dayOfWeek
          ];
          if (dayKey) {
            acc[dayKey].push(item);
          }
          return acc;
        },
        {
          sun: [],
          mon: [],
          tue: [],
          wed: [],
          thu: [],
          fri: [],
          sat: [],
        },
      );

      dispatch(setSchedule(updatedSchedule));
    } else {
      dispatch(
        setSchedule({
          sun: [],
          mon: [],
          tue: [],
          wed: [],
          thu: [],
          fri: [],
          sat: [],
        }),
      );
    }
  } catch (error) {
    console.error(error);
  }
};

export const saveSchedule = async (
  userId: string,
  selectedDate: string,
  startAt: string,
  finishAt: string,
  fetchUpdatedSchedule: (
    userId: string,
    dispatch: AppDispatch,
  ) => Promise<void>,
  dispatch: AppDispatch,
) => {
  const datePart = new Date(selectedDate).toISOString().split('T')[0];
  const data = {
    startAt: new Date(`${datePart}T${startAt}`).toISOString(),
    finishAt: new Date(`${datePart}T${finishAt}`).toISOString(),
    userId,
  };

  try {
    const response = await axiosInstance.put(`/Admin/Schedule`, data);
    if (response.status === 200) {
      await fetchUpdatedSchedule(userId, dispatch);
    }
  } catch (error) {
    console.error(error);
  }
};
