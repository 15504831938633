import dayjs from 'dayjs';
import React from 'react';
import { Client } from '../../../types';
import NonAvatar from '../CustomerList/CustomerListItem/icon/nonAvatar';
import style from './ClientDetailsModal.module.css';
import ExitLogo from './icons/exitLogo';

interface ClientDetailsModalProps {
  client: Client;
  onClose: () => void;
}

const ClientDetailsModal: React.FC<ClientDetailsModalProps> = ({
  client,
  onClose,
}) => {
  const formattedContractFinishAt = client.contractFinishAt
    ? dayjs(client.contractFinishAt).format('DD.MM.YYYY')
    : null; // Додаємо перевірку на null або undefined

  return (
    <div className={style.modalOverlay}>
      <div className={style.modalContent}>
        <div className={style.header}>
          <h5>Клієнт: {client.userName}</h5>
          <button onClick={onClose} className={style.closeButton}>
            <ExitLogo />
          </button>
        </div>
        <div className={style.avatarContainer}>
          {client.avatarUrl ? (
            <img
              src={client.avatarUrl}
              alt={`${client.userName}'s avatar`}
              className={style.avatar}
            />
          ) : (
            <div className={style.nonAvatar}>
              <NonAvatar />
            </div>
          )}
        </div>
        <div className={style.details}>
          <h5>Клієнт</h5>
          <div className={style.wrap}>
            <div className={style.warperInformation}>
              <h6>ПІБ КЛІЄНТА</h6>
              <p>{client.userName}</p>
            </div>
            <div className={style.warperInformation}>
              <h6>Email</h6>
              <p>{client.email}</p>
            </div>
            <div className={style.warperInformation}>
              <h6>Телефон</h6>
              <p>{client.phone}</p>
            </div>
          </div>
        </div>
        <div className={style.details}>
          <h5>Підписка</h5>
          <div className={style.wrap}>
            <div className={style.warperInformation}>
              <h6>Тариф</h6>
              <p>{client.plan}</p>
            </div>
            <div className={style.warperInformation}>
              <h6>Номер договору</h6>
              <p>{client.contractNumber}</p>
            </div>
            <div className={style.warperInformation}>
              <h6>Термін договору</h6>
              <p>
                {formattedContractFinishAt
                  ? `до ${formattedContractFinishAt}`
                  : ''}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientDetailsModal;
