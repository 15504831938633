import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { PushNotification } from '../../../types';

dayjs.extend(utc);
dayjs.extend(timezone);

export const daysOfWeekShort = ['нд', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'];

export const generateTimeOptions = () => {
  const options = [];
  for (let h = 0; h < 24; h++) {
    for (let m = 0; m < 60; m += 10) {
      const hour = String(h).padStart(2, '0');
      const minute = String(m).padStart(2, '0');
      options.push(`${hour}:${minute}`);
    }
  }
  return options;
};

export const notificationTypesOptions = [
  { value: '1', label: 'Акція' },
  { value: '2', label: 'Реклама' },
  { value: '3', label: 'Пропозиція' },
];

export const initialPushNotificationState = (
  values: PushNotification | null,
): PushNotification => {
  const localStartTime = dayjs.utc(values?.startDate).tz('Europe/Kyiv');

  const localEndTime = dayjs.utc(values?.endDate).tz('Europe/Kyiv');

  const localTime = dayjs.utc(values?.sendTime, 'HH:mm:ss').tz('Europe/Kyiv');

  return {
    name: values?.name ?? '',
    message: values?.message ?? '',
    typeId: String(values?.typeId) ?? '',
    sendTime: values?.sendTime ? String(localTime.format('HH:mm')) : '',
    startDate: values?.startDate ? localStartTime.toISOString() : '',
    endDate: values?.endDate ? localEndTime.toISOString() : '',
    isRecurring: values?.isRecurring ?? false,
  };
};
