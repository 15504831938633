import React, { useState, useEffect } from 'react';
import { Employee } from '../../../../types';
import DeleteIcon from '../icons/delete';
import style from './EmployeeItem.module.css';
import ResponsibleIcon from './icon/responsibleIcon';

interface EmployeeItemProps {
  employee: Employee;
  index: number;
  onDeleteClick: (employee: Employee) => void;
  showCheckboxes: boolean;
  selectedEmployees: string[];
  onToggleEmployee: (id: string, isSelected: boolean) => void;
}

const EmployeeItem: React.FC<EmployeeItemProps> = ({
  employee,
  index,
  onDeleteClick,
  showCheckboxes,
  selectedEmployees,
  onToggleEmployee,
}) => {
  const [isSelected, setIsSelected] = useState(employee.isResponsible);

  const toggleCheckbox = () => {
    const newState = !isSelected;
    onToggleEmployee(employee.id, newState);
    setIsSelected(newState);
  };

  useEffect(() => {
    setIsSelected(employee.isResponsible);
  }, [employee.isResponsible]);

  return (
    <div className={style.itemContainer}>
      <div className={style.additionalElement}>{index + 1}</div>
      <div className={style.wrapInfo}>
        <img src={employee.avatarUrl} alt={employee.userName} />
        <p>{employee.userName}</p>
      </div>
      <p>{employee.email}</p>
      <p>{employee.phone}</p>
      {isSelected && !showCheckboxes ? (
        <ResponsibleIcon />
      ) : (
        showCheckboxes && (
          <input
            type="checkbox"
            checked={isSelected}
            onChange={toggleCheckbox}
            disabled={!isSelected && selectedEmployees.length >= 2}
          />
        )
      )}
      <button
        onClick={() => onDeleteClick(employee)}
        className={style.deleteButton}
      >
        <DeleteIcon />
      </button>
    </div>
  );
};

export default EmployeeItem;
