import React from 'react';
import { Control, Controller, FieldError } from 'react-hook-form';
import Switch from 'react-switch';
import Input from '../input/input';
import Select from '../Select/Select';
import TextArea from '../TextArea/TextArea';
import DateInput from '../DateInput/DateInput';
import style from './FieldController.module.css';

interface FieldControllerProps {
  name: string;
  control: Control<any>;
  rules?: any;
  type: string;
  label: string;
  error?: FieldError | undefined;
  options?: { value: string; label: string }[];
  readOnly?: boolean;
  value?: string;
  onFocus?: () => void;
  onClick?: () => void;
}

const FieldController: React.FC<FieldControllerProps> = ({
  name,
  control,
  rules,
  type,
  label,
  error,
  options,
  readOnly = false,
  value,
  onClick,
}) => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    render={({ field }) =>
      type === 'select' ? (
        <Select
          {...field}
          label={label}
          options={options || []}
          error={error?.message}
          required
          value={value || String(field.value)}
        />
      ) : type === 'textarea' ? (
        <TextArea
          {...field}
          type={type}
          label={label}
          error={error?.message}
          required
          readOnly={readOnly}
          value={readOnly ? value : field.value}
        />
      ) : type === 'date' ? (
        <DateInput
          label={label}
          readOnly
          value={readOnly ? value : field.value}
          name={name}
          onClick={onClick}
          error={error?.message}
        />
      ) : type === 'switch' ? (
        <div className={style.toggleContainer}>
          <label>{label}</label>
          <Switch
            name={name}
            onChange={field.onChange}
            checked={field.value}
            onColor="#0a8080"
            offColor="#ccc"
            onHandleColor="#f2f8f8"
            offHandleColor="#fff"
            checkedIcon={false}
            uncheckedIcon={false}
          />
        </div>
      ) : (
        <Input
          {...field}
          type={type}
          label={label}
          error={error?.message}
          required
          readOnly={readOnly}
          value={readOnly ? value : field.value}
        />
      )
    }
  />
);

export default FieldController;
