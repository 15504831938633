import { configureStore } from '@reduxjs/toolkit';
import { loadFromLocalStorage, saveToLocalStorage } from './middleware';
import authReducer, {
  initialState as initialAuthState,
} from './reducers/authSlice';
import cancelTranslationReducer, {
  initialState as initialCancelTranslationState,
} from './reducers/cancelTranslationReducer';
import clientsReducer, {
  initialState as initialClientsState,
} from './reducers/clientsReducer';
import companiesReducer, {
  initialState as initialCompaniesState,
} from './reducers/companiesReducer';
import historyReducer, {
  initialState as initialHistoryState,
} from './reducers/historyReducer';
import scheduleReducer, {
  initialState as initialScheduleState,
} from './reducers/scheduleSlice';
import snackbarReducer, {
  initialState as initialSnackbarState,
} from './reducers/snackbarSlice';
import translatorsReducer, {
  initialState as initialTranslatorState,
} from './reducers/translatorsReducer';

const persistedState = loadFromLocalStorage();

const preloadedState = {
  auth: persistedState?.auth ?? initialAuthState,
  snackbar: persistedState?.snackbar ?? initialSnackbarState,
  schedule: persistedState?.schedule ?? initialScheduleState,
  translators: persistedState?.translators ?? initialTranslatorState,
  clients: persistedState?.clients ?? initialClientsState,
  companies: persistedState?.companies ?? initialCompaniesState,
  history: persistedState?.history ?? initialHistoryState,
  cancelTranslation:
    persistedState?.cancelTranslation ?? initialCancelTranslationState,
};

const store = configureStore({
  reducer: {
    auth: authReducer,
    snackbar: snackbarReducer,
    schedule: scheduleReducer,
    translators: translatorsReducer,
    clients: clientsReducer,
    companies: companiesReducer,
    history: historyReducer,
    cancelTranslation: cancelTranslationReducer,
  },
  preloadedState: preloadedState,
  devTools: true,
});

store.subscribe(() => saveToLocalStorage(store.getState()));

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
