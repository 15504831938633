import React, { useEffect, useRef } from 'react';
import style from './ConfirmationModal.module.css';

interface ConfirmModalProps {
  notifyName: string | undefined;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  notifyName,
  onConfirm,
  onCancel,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        onCancel();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onCancel]);

  return (
    <div className={style.modalOverlay}>
      <div ref={modalRef} className={style.modal}>
        <div className={style.modalContent}>
          <div>
            <h5 className={style.title}>Видалення нотифікації</h5>
          </div>
          <h5 className={style.message}>
            Ви впевнені, що хочете видалити нотифікацію?
          </h5>
          <div className={style.wrapInfo}>
            <p>{notifyName}</p>
          </div>
          <div className={style.modalActions}>
            <button className={style.confirmButton} onClick={onConfirm}>
              Так
            </button>
            <button className={style.cancelButton} onClick={onCancel}>
              Ні
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
