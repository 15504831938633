import React from 'react';
import CrossIcon from '../../СompanyForm/icon/Сross';
import style from './ConfirmDeleteModal.module.css';

interface ConfirmDeleteModalProps {
  userName: string;
  avatarUrl: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmDeleteModal: React.FC<ConfirmDeleteModalProps> = ({
  userName,
  avatarUrl,
  onConfirm,
  onCancel,
}) => {
  console.log(userName);
  return (
    <div className={style.overlay}>
      <div className={style.modal}>
        <div className={style.modalTitle}>
          <h6>Видалення співробітника</h6>
          <button onClick={onCancel} className={style.closeButton}>
            <CrossIcon />
          </button>
        </div>
        <p className={style.confirmText}>
          Ви впевнені, що хочете видалити співробітника?
        </p>
        <div className={style.userInfo}>
          <img src={avatarUrl} alt={userName} className={style.avatar} />
          <p>{userName}</p>
        </div>
        <div className={style.buttonGroup}>
          <button onClick={onConfirm} className={style.confirmButton}>
            Так
          </button>
          <button onClick={onCancel} className={style.cancelButton}>
            Ні
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDeleteModal;
