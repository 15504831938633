import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { showSnackbar } from '../../../store/reducers/snackbarSlice';
import style from './NotificationForm.module.css';
import DateModal from './DateModal/DateModal';
import FieldController from './FieldController/FieldController';
import FormHeader from './FormHeader/FormHeader';
import { createNotification, updateNotification } from '../pushService';
import {
  generateTimeOptions,
  notificationTypesOptions,
  initialPushNotificationState,
} from './helpers';
import { PushNotification } from '../../../types';

dayjs.extend(utc);
dayjs.extend(timezone);

interface CreateNewNotificationFormProps {
  onClose: () => void;
  onSave: () => void;
  notificationData: PushNotification | null;
}

const NotificationForm: React.FC<CreateNewNotificationFormProps> = ({
  onClose,
  onSave,
  notificationData,
}) => {
  const isEdit = Boolean(notificationData);
  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    watch,
  } = useForm<PushNotification>({
    defaultValues: initialPushNotificationState(notificationData),
    mode: 'onChange',
  });

  const [isStartDateModalOpen, setStartDateModalOpen] = useState(false);
  const [isEndDateModalOpen, setEndDateModalOpen] = useState(false);

  const openStartDateModal = () => setStartDateModalOpen(true);
  const closeStartDateModal = () => setStartDateModalOpen(false);

  const openEndDateModal = () => setEndDateModalOpen(true);
  const closeEndDateModal = () => setEndDateModalOpen(false);

  const handleStartDateSelect = (date: Date | null) => {
    setValue('startDate', new Date(date ?? '').toISOString(), {
      shouldValidate: true,
    });
  };

  const handleEndDateSelect = (date: Date | null) => {
    setValue('endDate', new Date(date ?? '').toISOString());
  };

  const onSubmit = async (data: PushNotification) => {
    const localStartTime = dayjs.tz(
      `${dayjs(data.startDate).format('DD.MM.YYYY')} ${data.sendTime}`,
      'DD.MM.YYYY HH:mm',
      'Europe/Kyiv',
    );
    const utcStartTime = localStartTime.utc();

    const localEndTime = dayjs.tz(
      `${dayjs(data.endDate ? data.endDate : new Date()).format('DD.MM.YYYY')} 23:59:59`,
      'DD.MM.YYYY HH:mm:ss',
      'Europe/Kyiv',
    );

    const utcEndTime = localEndTime?.utc();

    const notifyData = {
      id: isEdit ? notificationData?.id : undefined,
      name: data.name,
      message: data.message,
      typeId: Number(data.typeId),
      sendTime: utcStartTime.format('HH:mm:ss'),
      startDate: utcStartTime.toISOString(),
      endDate: data.endDate ? utcEndTime.toISOString() : undefined,
      isRecurring: data.isRecurring,
    };
    try {
      if (isEdit) {
        await updateNotification(notifyData);
        onSave();
      } else {
        await createNotification(notifyData);
        onSave();
      }
    } catch (error) {
      console.error('Помилка при збереженні нотифікації:', error);
      dispatch(
        showSnackbar({
          message: 'Сталася помилка при збереженні нотифікації',
          type: 'error',
        }),
      );
      throw error;
    }
  };

  const handleWrapperClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  return (
    <div className={style.formWrapper} onClick={handleWrapperClick}>
      <div className={style.modalWrap}>
        <FormHeader
          title={isEdit ? 'Редагувати нотифікацію' : 'Додати нотифікацію'}
          onClose={onClose}
        />
        <form
          className={style.companyForm}
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(onSubmit)(e);
            onClose();
          }}
          noValidate
        >
          <div className={style.row}>
            <FieldController
              name="name"
              control={control}
              rules={{
                required: "Це поле є обов'язковим",
                maxLength: {
                  value: 80,
                  message: 'Максимальна кількість символів 80',
                },
              }}
              type="text"
              label="Назва"
              error={errors.name}
            />
          </div>
          <div className={style.row}>
            <FieldController
              name="message"
              control={control}
              rules={{
                required: "Це поле є обов'язковим",
                maxLength: {
                  value: 300,
                  message: 'Максимальна кількість символів 300',
                },
              }}
              type="textarea"
              label="Текст"
              error={errors.message}
            />
          </div>
          <div className={style.row}>
            <FieldController
              name="typeId"
              control={control}
              type="select"
              label="Тип нотифікації"
              options={notificationTypesOptions}
              error={errors.typeId}
              value={String(watch('typeId')) || ''}
              rules={{
                required: "Це поле є обов'язковим",
              }}
            />
            <FieldController
              name="sendTime"
              control={control}
              type="select"
              label="Час нотифікації"
              options={generateTimeOptions().map((item) => ({
                value: item,
                label: item,
              }))}
              error={errors.sendTime}
              value={watch('sendTime')}
              rules={{
                required: "Це поле є обов'язковим",
              }}
            />
          </div>
          <div className={style.row}>
            <FieldController
              name="startDate"
              control={control}
              type="date"
              label="Дата початку"
              error={errors.startDate}
              value={watch('startDate')}
              rules={{
                required: "Це поле є обов'язковим",
              }}
              onClick={openStartDateModal}
            />
            <FieldController
              name="endDate"
              control={control}
              type="date"
              label="Дата закінчення"
              error={errors.endDate}
              value={watch('endDate')}
              onClick={openEndDateModal}
            />
          </div>
          <div className={style.row}>
            <FieldController
              name="isRecurring"
              control={control}
              type="switch"
              label="Регулярно повторювати"
            />
          </div>

          <div className={style.buttonContainer}>
            <button
              className={style.submitButton}
              type="submit"
              disabled={!isValid}
            >
              Зберегти
            </button>
          </div>
        </form>
        <DateModal
          isOpen={isStartDateModalOpen}
          onClose={closeStartDateModal}
          onSelect={handleStartDateSelect}
          title="Дата початку"
        />
        <DateModal
          isOpen={isEndDateModalOpen}
          onClose={closeEndDateModal}
          onSelect={handleEndDateSelect}
          title="Дата закінчення"
        />
      </div>
    </div>
  );
};

export default NotificationForm;
