import dayjs from 'dayjs';
import React, { useState } from 'react';
import CalendarIcon from '../icon/Calendar';
import styles from './DateInput.module.css';

interface DateInputProps {
  label: string;
  value: string;
  readOnly: boolean;
  name: string;
  onClick?: () => void;
  error?: string;
}

const DateInput = React.forwardRef<HTMLInputElement, DateInputProps>(
  ({ label, value, readOnly, name, onClick, error }, ref) => {
    const [isFocused, setIsFocused] = useState(false);

    return (
      <div className={styles.container} onClick={onClick}>
        <label className={styles.label}>{label}</label>
        <input
          ref={ref}
          type="text"
          value={value ? dayjs(value).format('DD.MM.YYYY') : undefined}
          className={`${styles.dateInput} ${isFocused ? styles.active : ''}`}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          readOnly={readOnly}
          name={name}
        />
        <div className={styles.calendarIcon}>
          <CalendarIcon />
        </div>
        {error && <span className={styles.error}>{error}</span>}
      </div>
    );
  },
);

DateInput.displayName = 'DateInput';

export default DateInput;
