import { PlansResponse } from '../../types';
import axiosInstance from '../../utils/axiosInstance';

export const fetchClientsPlansList = async (
  pageIndex: number,
  pageSize: number,
  searchString: string,
  orderDirection: 'asc' | 'desc' | '',
): Promise<PlansResponse> => {
  try {
    const response = await axiosInstance.post('/Admin/GetClientsPlansList', {
      pageIndex,
      pageSize,
      searchString,
      orderDirection,
    });
    return response.data as PlansResponse;
  } catch (error) {
    console.error('Error fetching active clients plans:', error);
    throw error;
  }
};

export const fetchClientsPlanRequestsList = async (
  pageIndex: number,
  pageSize: number,
  searchString: string,
  orderDirection: 'asc' | 'desc' | '',
): Promise<PlansResponse> => {
  try {
    const response = await axiosInstance.post(
      '/Admin/GetClientsPlanRequestsList',
      {
        pageIndex,
        pageSize,
        searchString,
        orderDirection,
      },
    );
    return response.data as PlansResponse;
  } catch (error) {
    console.error('Error fetching client plan requests:', error);
    throw error;
  }
};
