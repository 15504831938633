import React, { useEffect, useState } from 'react';
import style from './NotificationMenu.module.css';
import DeleteLogo from './icons/DeleteLogo';
import DetailsLogo from './icons/DetailsLogo';

interface ModalWindowCompanyProps {
  selectedNotificationId: string | null;
  onClose: () => void;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
  buttonRef: { current: HTMLButtonElement | null };
}

const NotificationMenu: React.FC<ModalWindowCompanyProps> = ({
  selectedNotificationId,
  onClose,
  onEdit,
  onDelete,
  buttonRef,
}) => {
  const [position, setPosition] = useState<{ top: string; left: string }>({
    top: '0px',
    left: '0px',
  });
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const modal = document.querySelector(`.${style.modalContent}`);
      if (
        modal &&
        buttonRef.current &&
        !modal.contains(event.target as Node) &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [buttonRef, onClose]);

  useEffect(() => {
    if (buttonRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      const modal = document.querySelector(
        `.${style.modalContent}`,
      ) as HTMLElement;

      if (modal) {
        const modalHeight = modal.offsetHeight;
        const modalWidth = modal.offsetWidth;
        const screenHeight = window.innerHeight;
        const screenWidth = window.innerWidth;

        let top = buttonRect.bottom;
        let left = buttonRect.left;

        if (top + modalHeight > screenHeight) {
          top = buttonRect.top - modalHeight;
        }

        if (left + modalWidth > screenWidth) {
          left = screenWidth - modalWidth;
        }

        setPosition({
          top: `${top}px`,
          left: `${left - 42}px`,
        });
      }
    }
  }, [buttonRef]);

  if (selectedNotificationId === null) return null;

  return (
    <div className={style.modalOverlay} onClick={onClose}>
      <div
        className={style.modalContent}
        style={{ top: position.top, left: position.left }}
        onClick={(e) => e.stopPropagation()}
      >
        <button
          onClick={() => onEdit(selectedNotificationId)}
          className={style.detailButton}
        >
          <DetailsLogo />
          Редагувати
        </button>

        <button
          onClick={() => onDelete(selectedNotificationId)}
          className={style.deleteButton}
        >
          <DeleteLogo />
          Видалити
        </button>
      </div>
    </div>
  );
};

export default NotificationMenu;
