import React, { useEffect, useRef, useState } from 'react';
import { FieldError, useForm } from 'react-hook-form';
import axiosInstance from '../../../utils/axiosInstance';
import DateModal from '../../Company/СompanyForm/DateModal/DateModal';
import FieldController from '../../Company/СompanyForm/FieldController/FieldController';
import Input from '../../Company/СompanyForm/input/input';
import CrossIcon from './icon/Сross';
import style from './NewPlanModal.module.css';

interface NewPlanModalProps {
  isOpen: boolean;
  selectedPlan: any;
  onSave: (plan: any) => void;
  onClose: () => void;
}

const NewPlanModal: React.FC<NewPlanModalProps> = ({
  isOpen,
  selectedPlan,
  onSave,
  onClose,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      planName: selectedPlan?.planName || '',
      numberDoc: '',
      timeDocumentFrom: '',
      timeDocumentTo: '',
      plan: selectedPlan?.newPlanId ? selectedPlan.newPlanId.toString() : '',
    },
  });

  const [plans, setPlans] = useState([]);
  const timeDocumentFrom = watch('timeDocumentFrom');
  const timeDocumentTo = watch('timeDocumentTo');
  const numberDoc = watch('numberDoc');
  const selectedPlanValue = watch('plan');
  const [isDateModalOpen, setIsDateModalOpen] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const hasFetchedPlans = useRef(false);

  useEffect(() => {
    const isValid =
      numberDoc.trim() !== '' &&
      selectedPlanValue !== null &&
      timeDocumentFrom !== '' &&
      timeDocumentTo !== '';
    setIsFormValid(isValid);
  }, [numberDoc, selectedPlanValue, timeDocumentFrom, timeDocumentTo]);

  useEffect(() => {
    if (selectedPlan?.newPlanId) {
      setValue('plan', selectedPlan.newPlanId.toString());
    }
  }, [selectedPlan, setValue]);

  useEffect(() => {
    if (isOpen && !hasFetchedPlans.current) {
      fetchPlans();
      hasFetchedPlans.current = true;
    }
  }, [isOpen]);

  const fetchPlans = async () => {
    try {
      const response = await axiosInstance.get('/Plans/GetPlansList');
      setPlans(response.data);

      if (selectedPlan?.newPlanId) {
        const selectedPlanFromResponse = response.data.find(
          (plan: { value: number }) => plan.value === selectedPlan.newPlanId,
        );
        if (selectedPlanFromResponse) {
          setValue('plan', selectedPlanFromResponse.value.toString());
        }
      }
    } catch (err) {
      console.error('Error fetching plans:', err);
    }
  };

  const openDateModal = () => setIsDateModalOpen(true);
  const closeDateModal = () => setIsDateModalOpen(false);

  const handleDateSelect = (dates: {
    startDate: Date | null;
    endDate: Date | null;
  }) => {
    if (dates.startDate) {
      setValue('timeDocumentFrom', dates.startDate.toISOString().split('T')[0]);
    }
    if (dates.endDate) {
      setValue('timeDocumentTo', dates.endDate.toISOString().split('T')[0]);
    }
    closeDateModal();
  };

  const formattedDateRange =
    timeDocumentFrom && timeDocumentTo
      ? `з ${new Date(timeDocumentFrom).toLocaleDateString()} по ${new Date(timeDocumentTo).toLocaleDateString()}`
      : '';

  const onSubmit = async (data: any) => {
    const payload = {
      clientId: selectedPlan?.id || selectedPlan?.userId,
      planId: parseInt(data.plan),
      contractNumber: data.numberDoc,
      contractStartAt: new Date(data.timeDocumentFrom).toISOString(),
      contractFinishAt: new Date(data.timeDocumentTo).toISOString(),
    };

    try {
      const response = await axiosInstance.patch(
        '/Admin/UpdateClientPlan',
        payload,
      );

      if (response.status === 200) {
        onSave(data);
      }
    } catch (err) {
      console.error('Error updating plan:', err);
    }
  };

  const handleOverlayClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className={style.modalOverlay} onClick={handleOverlayClick}>
      <div className={style.modalContent}>
        <div className={style.tittleModal}>
          <h3>Активувати тариф</h3>
          <button onClick={onClose}>
            <CrossIcon />
          </button>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className={style.modalForm}>
          <div className={style.contentInputs}>
            <FieldController
              name="numberDoc"
              control={control}
              type="text"
              label="Номер договору"
              error={errors.numberDoc as FieldError}
            />
            <div>
              <FieldController
                name="plan"
                control={control}
                type="select"
                label="Тариф"
                options={plans.map((plan: { value: number; name: string }) => ({
                  value: plan.value.toString(),
                  label: plan.name,
                }))}
                error={errors.plan as FieldError}
              />
            </div>
            <div onClick={openDateModal}>
              <Input
                type="text"
                label="Термін договору"
                value={formattedDateRange}
                readOnly
                name="timeDocumentRange"
                onChange={() => {}}
                onBlur={() => {}}
              />
            </div>
          </div>
          <div className={style.modalActions}>
            <button type="submit" disabled={!isFormValid}>
              Зберегти
            </button>
          </div>
        </form>
        <DateModal
          isOpen={isDateModalOpen}
          onClose={closeDateModal}
          onSelect={handleDateSelect}
        />
      </div>
    </div>
  );
};

export default NewPlanModal;
