import React from 'react';
import { Client } from '../../../../types';
import MoreVert from '../../icons/MoreVert';
import { getPlanClass } from '../helpers';
import style from './CustomerListItem.module.css';
import NonAvatar from './icon/nonAvatar';

interface CustomerListItemProps {
  client: Client;
  index: number;
  clientRefs: React.MutableRefObject<(HTMLButtonElement | null)[]>;
  handleClientMenuClick: (client: Client, index: number) => void;
}

const CustomerListItem: React.FC<CustomerListItemProps> = ({
  client,
  index,
  clientRefs,
  handleClientMenuClick,
}) => (
  <div
    key={`${client.id}-${index}`}
    className={
      index === clientRefs.current.length - 1
        ? `${style.clientItem} ${style.lastClientItem}`
        : style.clientItem
    }
  >
    <div>{index + 1}</div>
    <div className={style.inotify}>
      {client.avatarUrl ? (
        <img
          src={client.avatarUrl}
          alt={`${client.userName}'s avatar`}
          className={style.avatar}
        />
      ) : (
        <div className={style.nonAvatar}>
          <NonAvatar />
        </div>
      )}
      <div>{client.userName}</div>
    </div>
    <div>{client.email}</div>
    <div className={`${getPlanClass(client.plan)}`}>{client.plan}</div>
    <div>
      <p>
        {client.contractFinishAt
          ? `до ${new Date(client.contractFinishAt).toLocaleDateString(
              'uk-UA',
              {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              },
            )}`
          : 'Безтерміновий'}
      </p>
    </div>
    <div>{client.phone}</div>
    <button
      ref={(el) => (clientRefs.current[index] = el)}
      className={style.clientMenu}
      onClick={() => handleClientMenuClick(client, index)}
    >
      <MoreVert />
    </button>
  </div>
);

export default CustomerListItem;
