import dayjs from 'dayjs';
import { getFormattedDuration, TranslationHistoryItem } from '../../helpers';
import style from './HistoryListItem.module.css';

interface HistoryListItemProps {
  item: TranslationHistoryItem;
  dataLength: number;
  index: number;
}

export const HistoryListItem = ({
  item,
  dataLength,
  index,
}: HistoryListItemProps) => {
  return (
    <div
      className={`${style.historyItem} ${
        index === dataLength - 1 ? style.lastHistoryItem : ''
      }`}
    >
      <p>{index + 1}</p>
      <div className={style.useraName}>
        <p>{item.translator?.userName}</p>
        <p className={style.userNameLabel}>Перекладач</p>
      </div>
      <div className={style.useraName}>
        <p>{item.client?.userName}</p>
        <p className={style.userNameLabel}>Клієнт</p>
      </div>
      <div>
        <p>{dayjs(item.createdAt).format('HH:mm')}</p>
      </div>
      <div>
        <p>{dayjs(item.createdAt).format('DD.MM.YYYY')}</p>
      </div>
      <div>
        <p>{getFormattedDuration(item.callDuration)}</p>
      </div>
      <div>
        <p>{item.finishedBy}</p>
      </div>
    </div>
  );
};
